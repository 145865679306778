export const maybePluralise = (
	count: number,
	singular: string,
	plural: string
) => {
	if (count < 0) {
		throw new Error('Cannot be negative');
	}
	return `${count} ${count !== 1 ? plural : singular}`;
};

/* istanbul ignore next */
export const sendGtagEvent = (
	action: string,
	category: string,
	label: string,
	value: number
) => {
	if (typeof window !== 'undefined' && window.gtag) {
		window.gtag('event', action, {
			event_category: category,
			event_label: label,
			value: value
		});
	}
};

// Taken from here: https://growthrocks.com/blog/scroll-tracking-google-analytics/
/* istanbul ignore next */
export const scrollTracking = () => {
	if (typeof window !== 'undefined' && window.gtag) {
		document.addEventListener('scroll', function() {
			let h: HTMLElement = document.documentElement,
				b: HTMLElement = document.body,
				st: string = 'scrollTop',
				sh: string = 'scrollHeight';

			let percent: number = Math.ceil(
				Number(((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100)
			);

			if (percent == 25) {
				window.gtag('event', 'moreThan25%', {
					event_category: 'Scrolling',
					event_label: window.location.href,
					value: 0,
					non_interaction: true
				});
			} else if (percent == 50) {
				window.gtag('event', 'moreThan50%', {
					event_category: 'Scrolling',
					event_label: window.location.href,
					value: 0,
					non_interaction: true
				});
			} else if (percent == 75) {
				window.gtag('event', 'moreThan75%', {
					event_category: 'Scrolling',
					event_label: window.location.href,
					value: 0,
					non_interaction: true
				});
			} else if (percent == 90) {
				window.gtag('event', 'moreThan90%', {
					event_category: 'Scrolling',
					event_label: window.location.href,
					value: 0,
					non_interaction: true
				});
			}
		});
	}
};
