import * as React from 'react';

import {
	Container,
	Grid,
	GridItem,
	Card,
	InputContainer,
	Input,
	Label,
	Button,
	Icon,
} from '@ao-internal/components-react';

import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { sendGtagEvent } from '../helpers/helpers';

const teamImage = require('../images/team2.jpg');
const teamStructureImage = require('../images/team-structure.jpg');

const handleJobSearch = (e: React.FormEvent<EventTarget>): void => {
	e.preventDefault();

	const keyword = (e.target as HTMLFormElement).search.value;
	const aoJobsSearchUrl = 'https://www.ao-jobs.com/all-jobs/?keywords=';

	if (keyword) {
		void window.open(
			`${aoJobsSearchUrl}${encodeURIComponent(keyword)}`,
			'_blank'
		);
		sendGtagEvent('Teams Page', 'Job Search', keyword, 0);
	}
};

export interface TeamsProps {}

export const TeamsPage: React.SFC<TeamsProps> = (props) => (
	<React.Fragment>
		<Helmet
			title="AO Tech Teams"
			bodyAttributes={{
				class: 'teams',
			}}
		/>

		<Layout>
			<div
				className="blog-page__featured-image"
				style={{
					backgroundImage: `url(${teamImage})`,
				}}>
				<Container className="px-0 flex h-full sub-header">
					<Grid className="items-center">
						<GridItem
							key="1"
							span={12}
							className="h-full text-center items-center">
							<div>
								<div className="video-content">
									<h1 className="text-display text-white">
										Our Teams &amp; Principles
									</h1>
								</div>
							</div>
						</GridItem>
					</Grid>
				</Container>
			</div>

			<Container className="index__section-one text-center">
				<Card>
					<Grid className="pb-4">
						<GridItem span={12}>
							<h2 className="text-display">Team Structure</h2>
							<div className="text-body">
								<div>
									We work in embedded product teams set up for continuous
									delivery.
									<br />
									Each team includes all the people needed to develop
									opportunities from discovery to delivery.
								</div>
							</div>
						</GridItem>
					</Grid>
					<Grid className="items-center pt-6">
						<GridItem span={{ def: 12, md: 4 }}>
							<h3 className="text-display-sm">Bolton HQ</h3>
							<div>with multiple locations across the UK</div>
						</GridItem>
						<GridItem span={{ def: 12, md: 4 }} className="my-6 md:my-0">
							<h3 className="text-display-sm mb-4">Find a better tomorrow</h3>
							<form onSubmit={handleJobSearch}>
								<div className="mx-auto max-w-md">
									<InputContainer className="mb-4">
										{() => (
											<React.Fragment>
												<Label htmlFor="search-input" srOnly>
													Search by job title
												</Label>
												<Input
													id="search-input"
													inputMode="search"
													name="search"
													placeholder="Search by job title"
													defaultValue=""
													className="w-full border-0"
												/>
												<Icon
													as={Button}
													color="primary"
													icon="search"
													label="Search"
													size="lg"
													id="search-button"
												/>
											</React.Fragment>
										)}
									</InputContainer>
								</div>
							</form>
						</GridItem>
						<GridItem span={{ def: 12, md: 4 }}>
							<h3 className="text-display-sm">200 members</h3>
							<div>across 30 tech teams</div>
						</GridItem>
					</Grid>
				</Card>
			</Container>

			<h2 className="text-display text-center mt-8 mb-6">Team Disciplines</h2>

			<Container className="px-0">
				<Grid>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">Teamwork makes the dream work</h3>
							<p>
								We start with a problem or an opportunity and talk to the people
								in our business who are closest to it. We ship working software
								to real users in days, not months – and we use their feedback to
								inspire us on what we do next.
							</p>
						</div>
					</GridItem>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">
								Technology moves fast, but we move faster
							</h3>
							<p>
								We don't straggle in adopting a new technology, but we're not
								magpies either - we don’t try everything that's new, just for
								the sake of it. We standardise little and trust our teams to
								guide the right technology choices at the right time.
							</p>
						</div>
					</GridItem>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">
								Communication is a two-way street
							</h3>
							<p>
								We believe in honesty, transparency and effective communication.
								Our weekly all-hands meeting is an open invitation for people to
								ask whatever questions are on their minds. There are no taboo
								topics.
							</p>
						</div>
					</GridItem>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">Freedom and flexibility</h3>
							<p>
								We hire smart people, so we don’t do their thinking for them. We
								trust them to do the right thing for themselves and AO. We do
								loads to support and nurture them so they can achieve their
								potential.
							</p>
						</div>
					</GridItem>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">Never a blame game</h3>
							<p>
								However good we are, our systems are complex and there are bound
								to be occasional glitches. Rather than blame an individual, we
								use it as an opportunity to learn and improve.
							</p>
						</div>
					</GridItem>
					<GridItem span={{ def: 12, md: 4 }}>
						<div className="teams__panel">
							<h3 className="text-display-sm">We hire against our values</h3>
							<p>
								We're a united workforce that embody our company values in
								everything them do bold, smart, fun, caring and driven. Our
								autonomous teams have people of diverse backgrounds and thinking
								so we're constantly learning from each other.
							</p>
						</div>
					</GridItem>
				</Grid>
			</Container>

			<Container className="my-8">
				<Grid>
					<GridItem
						span={{ def: 12, md: 8 }}
						className="md:pr-12 mx-auto text-center">
						<div className="text-display-sm mb-6">
							"I'm surrounded by so many talented colleagues with such a wide
							variety of skills. We have a genuinely supportive atmosphere which
							is a great place to learn in."
						</div>
						<div className="text-base">- Sarah, Front End Developer</div>
					</GridItem>
				</Grid>
			</Container>
		</Layout>
	</React.Fragment>
);

export default TeamsPage;
